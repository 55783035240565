import React, { useState } from "react";
import _ from "lodash";
import { graphql} from "gatsby";
import { getSrc } from "gatsby-plugin-image";
import { useIntl, navigate, FormattedMessage  } from "gatsby-plugin-react-intl";

import Layout from "@components/Layout";
import SearchWidget from "@components/SearchWidget";
import PreviewCompatibleImage from "@components/PreviewCompatibleImage";
import ComingSoon from "@components/ComingSoon";

import { formatIntl } from "@utils/intl";
import { generateNewPath } from "@utils/url";


import "./index.sass";

export const ProductCategoryPageTemplate = ({ intl_title, image, productCategory, isPreview, is_soon, is_new }) =>  {
    const intl = useIntl();
    const title = formatIntl(intl, intl_title);
    const handleCategoryChange = (e, location, newCategoriesParam, queries, isMobile) => {
      if(!isMobile) {
        let newCategory = newCategoriesParam.filter(category => category != productCategory)[0];
        let newPath = "/search/product/"
        let {category, entity, ...newQueries} = queries;
        if (!newCategory) {
          newPath = generateNewPath({
            pathname: "/search/product/"
          }, newQueries);
        } else {
          newPath = generateNewPath({
            pathname: `/products/${newCategory}`
          }, newQueries);
        }
        navigate(newPath);
      }
    }
  
    const handleFilterSubmit = (e, location, newQueries, queries, isMobile) => {
      let newCategoriesParam = newQueries.category || [];
      let newPath = "/search/product/"
      const newQueryParam = _.cloneDeep(newQueries)
      if (newCategoriesParam.length != 1) {
        const {entity, ...newQuery} = newQueryParam
        newPath = generateNewPath({
          pathname: "/search/product/"
        }, newQuery);
      } else {
        const { category, entity, ...newQuery } = newQueryParam
        newPath = generateNewPath({
          pathname: `/products/${category}`
        }, newQuery);
      }
      navigate(newPath);
    }

    return (
        <section className="section">
            <div
              className="product-category-page"
            >
              <div className="product-category-page-header">
                    <PreviewCompatibleImage className="product-category-page-header-img" imageInfo={{
                      image: image,
                      alt: title
                    }}/>
                    <div className="product-category-page-header-wrapper">
                      <h2 className="product-category-page-header-title">{title}</h2>
                    </div>
              </div>
              <div className="product-category-page-content">
                  {!isPreview && !is_soon && <SearchWidget entity="product" overrideQueries={{category: [productCategory]}} onCategoryChange={handleCategoryChange} onFilterSubmit={handleFilterSubmit} />}
                  {is_soon &&
                    <div className="product-category-page-coming-soon">
                      <div className="product-category-page-coming-soon-icon">
                        <ComingSoon />
                      </div>
                      <div className="product-category-page-coming-soon-text">
                        <div className="product-category-page-coming-soon-title">
                          <FormattedMessage id="ProductCategoryPage_COMING_SOON_TITLE" />
                        </div>
                        <div className="product-category-page-coming-soon-subtitle">
                          <FormattedMessage id="ProductCategoryPage_COMING_SOON_SUBTITLE" />
                        </div>
                      </div>
                    </div>
                  }
              </div>
            </div>
        </section>
    );
}

const ProductCategoryPage = ({ data }) => {
  const intl = useIntl();
  const { intl_title, image, productCategory, is_soon, is_new } = data.markdownRemark.frontmatter;
  const title = intl.formatMessage({id: "ProductCategoryPage_PAGETITLE"}, {category_title: formatIntl(intl, intl_title)});
  const customOgImage = image ? getSrc(image) : undefined
  return (
    <Layout customTitle={title} customOgImage={customOgImage} isHeaderFixed isFooterFixed>
      <ProductCategoryPageTemplate intl_title={intl_title} image={image} productCategory={productCategory} is_new={is_new} is_soon={is_soon} />
    </Layout>
  )
}

export default ProductCategoryPage;

export const productCategoriesPageQuery = graphql`
  query ProductCategoryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
        html
        frontmatter {
            intl_title {
                en
                id
            }
            image {
                childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
                }
            }
            is_soon
            is_new
            productCategory
        }
    }
  }
`;
